import {toAwsS3Url} from '_metronic/helpers'
import clsx from 'clsx'
import {FC, useEffect} from 'react'
import {useLocation} from 'react-router-dom'

const BasicLayout: FC<{children: JSX.Element[] | JSX.Element}> = ({children}) => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  const location = useLocation()

  const currentPath = () => {
    const trim = location.pathname.split('/')
    if (trim[trim.length - 1] === 'selected-role') return false
    return true
  }

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        <div
          className='d-flex flex-column flex-lg-row-auto w-xl-700px positon-xl-relative'
          style={{backgroundColor: !currentPath() ? '#F64E60' : '#E3CC02'}}
        >
          <div className='d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-700px scroll-y'>
            <div className='d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20'>
              <div className='py-9 mb-5'>
                {/* <img alt='Logo' src={toAwsS3Url('/media/logos/logo-1-dark.svg')} className='h-60px' /> */}
              </div>
              <h1 className='fw-bolder fs-3qx mt-md-5 pb-2 pb-md-2' style={{color: '#ffff'}}>
                Your One-Stop Application
              </h1>
              <h2 className='fw-bolder fs-1qx pb-5 pb-md-5' style={{color: '#ffff'}}>
                Teacher-empowering, student-oriented
              </h2>
            </div>
            <div
              className='d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px'
              style={{
                backgroundImage: `url(${toAwsS3Url('/media/illustrations/sigma-1/12.png')})`,
              }}
            />
          </div>
        </div>

        <div className='d-flex flex-column flex-lg-row-fluid py-10'>
          <div className='d-flex flex-center flex-column flex-column-fluid'>
            <div className={clsx('p-10 p-lg-5 mx-auto', {'w-lg-500px': currentPath()})}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BasicLayout
