import PATH from '../path/path'
import {IDataRoutes} from './routers.model'
import {AuthPage} from 'app/modules/auth/redux/auth'
import {Welcome} from 'app/modules/auth/components/welcome/Welcome'
import {VerifyEmail} from 'app/modules/auth/components/verify-email/VerifyEmail'
import {PasswordConfirmation} from 'app/modules/auth/components/password-confirmation/PasswordConfirmation'
import {VerticalRegistration} from 'app/modules/registration/VerticalRegistration'
import OAuthGoogle from 'app/modules/auth/OAuthGoogle2'

const Routes: IDataRoutes = {
  private: {
    'Admin School': [
      {
        layout: 'MainLayout',
        routes: [],
      },
      {
        layout: 'FullLayout',
        routes: [],
      },
    ],
    Teacher: [
      {
        layout: 'MainLayout',
        routes: [],
      },
      {
        layout: 'FullLayout',
        routes: [],
      },
    ],
    Student: [
      {
        layout: 'MainLayout',
        routes: [],
      },
      {
        layout: 'FullLayout',
        routes: [],
      },
    ],
    user: [
      {
        layout: 'MainLayout',
        routes: [],
      },
      {
        layout: 'FullLayout',
        routes: [],
      },
    ],
  },
  public: {
    user: [
      {
        layout: 'FullLayout',
        routes: [
          {
            path: PATH.AUTH_PAGE,
            component: AuthPage,
            title: 'GK Enterprise',
          },
          {
            path: PATH.WELCOME_PAGE,
            component: Welcome,
            title: 'GK Enterprise',
          },
          {
            path: PATH.VERIFY_EMAIL,
            component: VerifyEmail,
            title: 'GK Enterprise',
          },
          {
            path: PATH.PASSWORD_CONFIRMATION,
            component: PasswordConfirmation,
            title: 'GK Enterprise',
          },
          {
            path: PATH.REGISTRATION_PAGE,
            component: VerticalRegistration,
            title: 'GK Enterprise',
          },
          {
            path: PATH.GOOGLE_OAUTH_PAGE,
            component: OAuthGoogle,
            title: 'GK Enterprise',
          },
        ],
      },
    ],
  },
}

export default Routes
