import React from 'react'
import {GoogleOAuthProvider, useGoogleLogin} from '@react-oauth/google'
import addEventParentIframe from 'app/services/addEventParentIframe'

const OauthGoogle = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_KEY || ''}>
      <CustomGoogleSignIn />
    </GoogleOAuthProvider>
  )
}

const CustomGoogleSignIn = () => {
  const login = useGoogleLogin({
    onSuccess: async (res) => {
      try {
        // Fetch user profile using the access_token
        const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            Authorization: `Bearer ${res.access_token}`,
          },
        })

        const userInfo = await userInfoResponse.json()
        // const jwtParts = res.access_token.split('.')
        const respondSignin = {
          tokenId: res.access_token, // Using access_token as tokenId
          googleId: userInfo.sub,
          profileObj: {
            googleId: userInfo.sub,
            imageUrl: userInfo.picture,
            email: userInfo.email,
            name: userInfo.name,
            givenName: userInfo.given_name,
            familyName: userInfo.family_name,
          },
        }
        // Send data to the parent iframe
        addEventParentIframe('googleLogin', respondSignin)
      } catch (error) {
        // console.error('Failed to fetch user info:', error);
      }
    },
    onError: () => {
      console.log('Login Failed')
    },
  })

  // Button for custom Google login
  return (
    <div className='bg-white h-100 w-100 d-flex justify-content-center align-items-start'>
      <div onClick={() => login()} className='w-100 btn border border-gray-500'>
        <img
          src='https://developers.google.com/identity/images/g-logo.png'
          alt='Google logo'
          className='me-3 h-25px'
        />
        <span className='fw-bolder text-gray-800 fs-5'>Login with Google</span>
      </div>
    </div>
  )
}

export default OauthGoogle
